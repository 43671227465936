<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    right
    temporary
    width="217"
    @input="(payload) => $emit('drawerUpdate', payload)"
  >
    <template v-slot:prepend>
      <div class="close">
        <v-icon color="primary" @click="drawer = false">
          mdi-chevron-right
        </v-icon>
      </div>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="drawer__header">{{
            $t("header.menu")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="deep-primary--text text--primary-4"
      >
        <v-list-item
          v-for="(listItem, listItemIndex) in list"
          :key="`menu-${listItemIndex}`"
          :to="listItem.to"
          link
          exact
          :class="[listItemIndex === 2 && /document/.test($route.path) ? 'v-list-item--active' : '']"
        >
          <v-list-item-icon>
            <component :is="listItem.iconComponent"></component>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ listItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <div class="logout" @click="logout">
      <IconLogout />
      <div class="logout__text">Deconnexion</div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppNavigationDrawer",
  props: {
    active: {
      type: Boolean,
    },
  },
  components: {
    IconMenu1: () => import("~cp/Icons/IconMenu1"),
    IconMenu2: () => import("~cp/Icons/IconMenu2"),
    IconMenu3: () => import("~cp/Icons/IconMenu3"),
    IconLogout: () => import("~cp/Icons/IconLogout")
  },
  data() {
    return {
      group: null,
      drawer: false,
      list: [
        {
          title: this.$t("header.myAccount"),
          to: { name: "Account" },
          iconComponent: "IconMenu1",
        },
        {
          title: this.$t("header.myProjects"),
          to: { name: "Home" },
          iconComponent: "IconMenu2",
        },
        {
          title: this.$t("header.myDoc"),
          to: { name: "DocumentListOfQuotaStart" },
          iconComponent: "IconMenu3",
        },
      ],
    };
  },
  watch: {
    active(val) {
      this.drawer = val;
    },
  },
  methods: {
    logout() {
      this.drawer = false;
      this.$store.commit("SET_LOADER_STATUS", true);
      this.$store.commit("authModule/LOGOUT");
      this.$Tawk.$endChat();
    },
  },
};
</script>

<style src="./AppNavigationDrawer.scss" lang="scss" scoped></style>
